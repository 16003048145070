import { render, staticRenderFns } from "./peopleUserEditDetails.vue?vue&type=template&id=0e64a224&scoped=true"
import script from "./peopleUserEditDetails.vue?vue&type=script&lang=js"
export * from "./peopleUserEditDetails.vue?vue&type=script&lang=js"
import style0 from "./peopleUserEditDetails.vue?vue&type=style&index=0&id=0e64a224&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e64a224",
  null
  
)

export default component.exports