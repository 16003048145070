<template>
	<div>
		<!-- 编辑记录 -->
		<div class="content1">
			<div class="all_style">
				<!-- <el-button size="small" @click="backtrack">返回</el-button> -->
        <div class="outer-row">
          <div
              style="
							font-size: 16px;
							font-weight: bolder;
							margin: 10px 0;
						"
          >
            用户信息
          </div>
          <div class="outer-rol">
            <div class="title">
              <span>基本信息</span>
            </div>
            <div class="outer-rol-info">
              <table class="desctable" cellspacing="0" cellpadding="0">
                <tr>
                  <th>户主姓名</th><td>{{ ruleForm.residentName }}</td>
                  <th>户主电话</th><td>{{ ruleForm.mobile }}</td>
                  <th>用户分类</th><td>{{ ruleForm.userTypeName }}</td>
                </tr>
                <tr>
                  <th>身份证号</th><td>{{ ruleForm.idnumber }}</td>
                  <th>片区</th><td>{{ ruleForm.addrName }}{{ ruleForm.addrsName }}{{ ruleForm.addrssName }}</td>
                  <th>地址</th><td>{{ ruleForm.address }}</td>
                </tr>
              </table>

            </div>
          </div>

          <div class="outer-rol">
          <div class="title">
            <span>安检信息</span>
          </div>
          <div class="outer-rol-info">
            <table class="desctable" cellspacing="0" cellpadding="0">
              <tr>
                <th>安检联系人</th><td>{{ ruleForm.contactName }}</td>
                <th>联系电话</th><td>{{ ruleForm.contactMobile }}</td>
                <th>是否为租户</th><td>{{
                  ruleForm.islessee === null
                      ? ""
                      : ruleForm.islessee == 1
                          ? "是"
                          : "否"
                }}</td>
              </tr>
            </table>
          </div>
        </div>
          <div class="outer-rol">
            <div class="title">
              <span>表具信息</span>
            </div>
            <div class="outer-rol-info">
              <table class="desctable" cellspacing="0" cellpadding="0">
				
				<tr v-for="(item,index) in getTdEl()" :key="index">				
                  <th v-if="item[0]">{{item[0].name}}</th><td v-if="item[0]">{{item[0].value}}</td>
				  <th v-if="!item[0]"></th><td v-if="!item[0]"></td>
				  <th v-if="item[1]">{{item[1].name}}</th><td v-if="item[1]">{{item[1].value}}</td>
				  <th v-if="!item[1]"></th><td v-if="!item[1]"></td>
				  <th v-if="item[2]">{{item[2].name}}</th><td v-if="item[2]">{{item[2].value}}</td>
				  <th v-if="!item[2]"></th><td v-if="!item[2]"></td>
                </tr>
                <!-- <tr>
                  <th>表编号</th><td>{{ ruleForm.sheetSteel }}</td>
                  <th v-if="ruleForm.tableTypeName">燃气表类型</th><td v-if="ruleForm.tableTypeName">{{ ruleForm.tableTypeName }}</td>
                  <th v-if="ruleForm.tableDirectionName">表向</th><td v-if="ruleForm.tableDirectionName">{{ruleForm.tableDirectionName}}</td>
                </tr>
                <tr>
                  <th v-if="ruleForm.tableVenderName">燃气表厂家</th><td v-if="ruleForm.tableVenderName">{{ ruleForm.tableVenderName }}</td>
                  <th v-if="ruleForm.tableTitles">表封号</th><td v-if="ruleForm.tableTitles">{{ ruleForm.tableTitles }}</td>
                  <th v-if="ruleForm.openDate">开户日期</th><td v-if="ruleForm.openDate">{{ruleForm.openDate}}</td>
                </tr>
                <tr>
                  <th v-if="ruleForm.receiveStove">所接炉具</th><td v-if="ruleForm.receiveStove">{{ ruleForm.receiveStove }}</td>
				  <th  v-if="ruleForm.remark">备注</th><td  v-if="ruleForm.remark">{{ ruleForm.remark }}</td> -->
                  <!-- <th>对接系统</th><td>{{
                    getDictLabel(
                        tableSystemList,
                        ruleForm.tableSystem,
                        "id",
                        "label"
                    )
                  }}</td>
                  <th>识别号</th><td>{{ruleForm.identifyNumber}}</td> -->
                <!-- </tr> -->
              </table>
            </div>
          </div>
				</div>
        
			</div>
      <div class="details-info" v-if="isShow">
				<div class="info-collect">
					<info-collect :data="itemData">
					</info-collect>
				</div>
				<!-- <div class="issue-register">
					<issue-register :data="scuItemData">
					</issue-register>
				</div> -->
			</div>
		</div>
    
	</div>
</template>


<script>
import InfoCollect from "../../workorder/component/infoCollect";
	import IssueRegister from "../../workorder/component/issueRegister";
	import { getDictLabel } from "@/utils/common";
  import {
		getHistorySecurityUser,
		getSecurityUser,
		getdataCheckRecord,
		dataBatchAudit,
	} from "@/RequestPort/workorder/maintenRecord";
	export default {
		name: "peopleUserEdit",
		data() {
			return {
				getDictLabel,
				ruleForm: {},
				tableSystemList: [
					{ label: "卡表系统", id: 1 },
					{ label: "营收系统", id: 2 },
				],
        tableData:[],
        scuItemData: [],
				itemData: [],
			};
		},
    components: {
			InfoCollect,
			IssueRegister
		},
    computed: {
			isShow() {

				let data = this.tableData.filter(el=>el.safetyResults == 1 || el.reviewResults == 1);
        console.log(data.length>0,"data.length>0")
				return data.length>0
			},
		},
		created() {
			if (this.$route.query.obj) {
				let arr = [];
				let ruleForm = JSON.parse(this.$route.query.obj);
				if (ruleForm.addrId) arr.push(ruleForm.addrId);
				if (ruleForm.addrsId) arr.push(ruleForm.addrsId);
				this.$set(ruleForm, "addrs", arr);
				this.ruleForm = ruleForm;
        this.getDetails();
			}
		},
		methods: {



			/**
			 * 返回
			 */
			backtrack() {
				this.$router.back(-1);
			},
			getTdEl(){
				//这么写是因为表格得跟上面的表格自动对齐，根据屏幕尺寸显示。且不form里没有的数据 不显示在table里
              let arr=[[]]
			  arr[0].push({name:'表编号',value:this.ruleForm.sheetSteel})
			  if(this.ruleForm.tableDirectionName){
				arr[0].push({name:'表向',value:this.ruleForm.tableDirectionName})
			  }
			  if(this.ruleForm.tableVenderName){
				arr[0].push({name:'燃气表厂家',value:this.ruleForm.tableVenderName})
			  }
			  if(this.ruleForm.tableTitles){
                let item={name:'表封号',value:this.ruleForm.tableTitles}
				if(arr[0].length==3){
					if(!arr[1])arr.push([])
					arr[1].push(item)
				}else{
					arr[0].push(item)
				}				
			  }
			  if(this.ruleForm.openDate){
				let item={name:'开户日期',value:this.ruleForm.openDate}
				if(arr[0].length==3){
					if(!arr[1])arr.push([])
					arr[1].push(item)
				}else{
					arr[0].push(item)
				}
			  }
			  if(this.ruleForm.receiveStove){
				let item={name:'所接炉具',value:this.ruleForm.receiveStove}
				if(arr[0].length==3){
					if(!arr[1])arr.push([])
					arr[1].push(item)
				}else{
					arr[0].push(item)
				}
			  }
			  if(this.ruleForm.remark){
				let item={name:'备注',value:this.ruleForm.remark}
				if(arr[0].length<3){
					arr[0].push(item)
				}else if(arr[1].length<3){
					if(!arr[1])arr.push([])
					arr[1].push(item)
				}else{
					if(!arr[2])arr.push([])
					arr[2].push(item)
				}
			  }
			  return arr

			},
      getDetails() {
        // query: {
				// 		dataId: row.dataId,
				// 		type: (row.safetyResults==1 && row.statusFlag==1) || row.finishType == 3 ? 1 : 0,
				// 		taskId: row.taskId,
				// 		residentId: row.residentId,
				// 		safetyResults: row.safetyResults,
				// 		auditStatus: row.auditStatus,
				// 	} 
				//  safetyResults 安检结果 1正常入户 2到访不遇 3拒绝安检 finishType 3已完成
        this.ruleForm.type = (this.ruleForm.safetyResults==1 && this.ruleForm.statusFlag==1) || this.ruleForm.finishType == 3 ? 1 : 0
        this.ruleForm.checkUserId = this.ruleForm.residentId
				let { taskId, checkUserId, type, dataId } = this.ruleForm;
        console.log(taskId, checkUserId, type, dataId ,"taskId, checkUserId, type, dataId ",this.ruleForm)
        if(!dataId) { return }
				getdataCheckRecord({ residentId: checkUserId, dataId }).then(
					(res) => {
						this.userData = res.data.arumUserPageVO;
						this.tableData = res.data.arumDataCheckRecordVOs;
					}
				);
				if (type == 1) {
					getHistorySecurityUser({ checkUserId, taskId,dataId }).then((res) => {
						this.detailsData = res.data;
						this.scuItemData = res.data.scuItem;
						this.table = res.data.table;
						this.itemData = res.data.item;
						this.auditStatus = res.data.auditStatus
						// console.log(res);
					});
				} else {
					getSecurityUser({ checkUserId, taskId,dataId }).then((res) => {
						this.detailsData = res.data;
						this.scuItemData = res.data.scuItem;
						this.table = res.data.table;
						this.itemData = res.data.item;
						this.auditStatus = res.data.auditStatus
						// console.log(res);
					});
				}
			},
		},
	};
</script>

<style lang="less" scoped>
.desctable{
  width: 100%;
  line-height: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  th{border: 1px solid #F0F2F5;padding:10px;text-align: right;width:125px;background: #F9F9F9;font-weight: normal;}
  td{border: 1px solid #F0F2F5;padding:10px;width:350px;}
}
.title{
  font-size: 15px;font-weight: bolder;margin-bottom: 10px;margin-top:8px;
}
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;margin-top: -2px;
}
.outer-row {
  .outer-rol {
    padding-left: 15px;
    padding-bottom: 10px;
    .outer-rol-info {
      .el-col {
        padding-bottom: 10px;
      }
      .info {
        display: flex;
        .label {
          margin-right: 5px;
        }
        // .text {
        // }
      }
    }
  }
}
	.content1 {
		position: relative;
		margin: 10px;
    overflow: auto;
		height: calc(100vh - 91px);
	}
	.all_style {
		padding: 10px;
		border-radius: 5px;
		background-color: #ffffff;
		// padding-bottom: 30px;
		box-sizing: border-box;
		

		.btns {
			// position: absolute;
			// width: 99%;
			// left: 0;
			// bottom: 20px;
			text-align: center;
		}
		.peopleUserInfo {
			max-width: 800px;
			margin-top: 10px;
			padding-left: 20px;
			.peopleUserList {
				.title {
					font-size: 18px;
					font-weight: bolder;
					margin-bottom: 10px;
				}
				.info {
					display: flex;
					margin-bottom: 10px;
					.name {
						width: 80px;
						text-align: right;
						margin-right: 5px;
					}
					.text {
						flex: 1;
						overflow: hidden;
					}
				}
			}
		}
	}
  .details-info {
				display: flex;
				margin-top: 10px;
				.info-collect {
					flex: 1;
					margin-right: 10px;background: #fff;padding:15px;border-radius: 6px;
				}
				.issue-register {
					flex: 2;background: #fff;padding:15px;border-radius: 6px;
				}
			}
</style>